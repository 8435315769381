<template>
  <div class="container b-card p-8 h-screen">
    <b-button
      v-if="screen_size > 600"
      variant="primary"
      class="mx-auto w-full my-4"
      @click="downloadResume"
    >
      Download resume file
    </b-button>
    <iframe
      v-if="document_type == 'pdf'"
      :src="file_source"
      width="100%"
      height="100%"
      frameborder="0"
    />

    <iframe
      v-else
      :src="file_source"
      width="100%"
      height="100%"
      frameborder="0"
    />
  </div>
</template>
<script>

import {
  BCard,
  BFormGroup,
  BFormInput,
  BButton,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BModal,
  VBModal,
  BBadge,
  BAvatar,
  BOverlay,
  BFormCheckbox,
  BFormInvalidFeedback,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BFormGroup,
    BFormInput,
    BButton,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BModal,
    VBModal,
    BBadge,
    BAvatar,
    BOverlay,
    BFormCheckbox,
    BFormInvalidFeedback,
  },
  data() {
    return {
      resume_id: '',
      document_type: 'pdf',
      // https://api.headhuntershq.com/api/media/601853647.docx
      file_source: '',
      screen_size: '',
      isLoading: true,
    }
  },

  created() {
    const { token } = this.$store.state.auth.ActiveUser || {}
    const resume_id = this.$route.params.resumeid || {}
    this.resume_id = resume_id
    // this.isLoading = true;
    this.getResumeFile(resume_id)
    this.screen_size = window.screen.width
  },

  methods: {
    getResumeFile(resume_file) {
      const company = this.$store.state.auth.ActiveUser.company_name
      if (resume_file.includes('pdf')) {
        this.document_type = 'pdf'
        var fileURL = `${this.$http.defaults.baseURL}/api/extract-resume/${resume_file}/${company}`
        // <iframe src="https://docs.google.com/viewer?url=http://infolab.stanford.edu/pub/papers/google.pdf&embedded=true" style="width:600px; height:500px;" frameborder="0"></iframe>

        this.file_source = `https://docs.google.com/viewer?url=${fileURL}&embedded=true`
        this.isLoading = false
      } else {
        this.document_type = 'docx'
        var fileURL = `${this.$http.defaults.baseURL}/api/extract-resume/${resume_file}/${company}`
        // https://view.officeapps.live.com/op/embed.aspx?src=
        this.file_source = `https://view.officeapps.live.com/op/embed.aspx?src=${fileURL}`
        this.isLoading = false
      }
    },

    downloadResume() {
      const company = this.$store.state.auth.ActiveUser.company_name
      const file_link = `${this.$http.defaults.baseURL}/api/extract-resume/${this.resume_id}/${company}`
      this.$http.get(file_link, { responseType: 'arraybuffer' }).then(response => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute('download', this.resume_id)
        document.body.appendChild(fileLink)
        fileLink.click()
      })
    },
  },
}
</script>
